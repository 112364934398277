import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { NIcon, TextError, NButton } from "components/Nimbiv2";
import {
  AnonymousContainer,
  CardNimbi,
  DividerH,
  IconContainer,
  RowItem,
} from "components/Nimbiv2/styled";
import {
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClone,
  faEnvelope,
  faExclamationCircle,
  faInfoCircle,
  faLandmark,
  faPhone,
  faPhoneArrowUpRight,
  faUsersClass,
} from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { getDataFromLocalStorage, getGlobalConfig } from "utils";
import { IconStudent } from "assets/images";
import { useTranslation } from "react-i18next";
import useTranslationLocal from "hooks/useTranslationLocal";
import {
  fetchEnterableActionPlans,
  fetchInfoCustomPerson,
} from "store/actions/async/student-async.actions";
import * as StudentActions from "store/actions/student.actions";
import { NBadge } from "components";
import { Formik } from "formik";
import { enterActionPlan } from "services/student.service";
import { toast } from "react-toastify";
import { colorsBadge } from "utils/constants";
import copy from "copy-to-clipboard";
import { fetchListMomentReason } from "services/momentService";

const StudentProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const { id } = useParams();
  const [selectTracking, setSelectTracking] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [momentsActive, setMomentsActive] = useState({});
  const extras = getDataFromLocalStorage("extras", {});
  const [check, setCheck] = useState(false);
  const [reason, setReason] = useState(null);
  const [loadingReason, setLoadingReason] = useState(false);
  // initial get store
  const {
    personsProfile,
    pending: { profile: isPendingProfile },
    personCourses,
  } = useSelector((state) => state.crm);

  const {
    enterable_action_plans,
    //pending: { enterable_action_plans: isPendingEnterable },
  } = useSelector((state) => state.student);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (selectTracking) {
      dispatch(
        fetchEnterableActionPlans(id, {
          student_tracking_id: selectTracking.student_tracking_id,
        })
      );
    }
  }, [selectTracking, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (personCourses.length > 0) {
      const new_array = personCourses.filter(
        (item) => item.id !== 0 || item.is_active === true
      );
      if (new_array.length > 0) {
        const itemSelect = new_array[0].id;
        dispatch(fetchInfoCustomPerson(id, { course: itemSelect }));
        dispatch(
          StudentActions.setStudentCourseSelected({
            courseSelected: itemSelect,
          })
        );
        setSelectTracking(new_array[0]);
      } else {
        dispatch(fetchInfoCustomPerson(id));
      }
    }
  }, [personCourses, dispatch, id]);

  const changeTrackingObject = (data) => {
    dispatch(fetchInfoCustomPerson(id, { course: data }));
    const new_array = personCourses.filter(
      (item) => item.id === parseInt(data)
    );
    dispatch(
      StudentActions.setStudentCourseSelected({
        courseSelected: data,
      })
    );
    setSelectTracking(new_array[0]);
  };

  const onFormSubmit = useCallback(
    async (values) => {
      setLoading(true);
      values["student_tracking_id"] = selectTracking.student_tracking_id;
      const { data, status } = await enterActionPlan(id, values);
      if (status === 200) {
        toast.success("Acción realizada exitosamente");
        let moments = { ...momentsActive };
        moments[selectTracking.student_tracking_id] = data;
        setMomentsActive(moments);
        dispatch(
          fetchEnterableActionPlans(id, {
            student_tracking_id: selectTracking.student_tracking_id,
          })
        );
        setCheck(false);
        setShow(false);
      } else {
        toast.error("Error en la acción solicitada");
      }
    },
    [selectTracking, id, dispatch, momentsActive]
  );

  const validate = (values) => {
    const errors = {};
    if (!values.action_plan) {
      errors.action_plan = t("selection_required");
    }

    return errors;
  };

  const validateDocument = useMemo(() => {
    return (
      personsProfile &&
      personsProfile.document_type &&
      personsProfile.document_number &&
      personsProfile.document_type !== "" &&
      personsProfile.document_number !== ""
    );
  }, [personsProfile]);

  const StudentTrackingIsActive = useMemo(() => {
    return selectTracking?.student_tracking_active ?? false;
  }, [selectTracking]);

  const colorStatus = {
    true: colorsBadge.light.success,
    false: colorsBadge.light.inactive,
  };

  const copyInfo = (data) => {
    copy(data);
    toast.info(`Copiado: ${data}`);
  };

  const getListReason = async (value) => {
    if (value) {
      setLoadingReason(true);
      const { data } = await fetchListMomentReason({ moment_id: value });
      setReason(data);
      setLoadingReason(false);
    } else {
      setReason(null);
    }
  };

  return (
    <div>
      {isPendingProfile && (
        <CardNimbi radius="8px" padding="32px 25px">
          <Spinner
            as="span"
            animation="border"
            size="bg"
            role="status"
            aria-hidden="true"
          />
        </CardNimbi>
      )}
      {!isPendingProfile && personsProfile && (
        <CardNimbi radius="8px" padding="29px 26px">
          <div
            className="d-flex align-items-center"
            style={{ position: "absolute", right: "25px" }}
          >
            <NBadge
              text={_.upperFirst(
                StudentTrackingIsActive ? t("active") : t("inactive")
              )}
              customStyle={{
                borderRadius: "64px",
              }}
              backgroundColor={colorStatus[StudentTrackingIsActive].background}
              fontColor={colorStatus[StudentTrackingIsActive].fontColor}
            />
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`tooltip-resp-active`}>
                  {_.upperFirst(
                    t("student_status_regarding", {
                      student: translation("student", "", t("student")),
                    })
                  )}
                </Tooltip>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
            </OverlayTrigger>
          </div>
          <div>
            <img
              src={IconStudent}
              alt="icon student"
              style={{ width: "67px", height: "67px" }}
            />
          </div>
          <div style={{ marginTop: "16px" }}>
            <NameTitle>
              {personsProfile.first_name}{" "}
              <AnonymousContainer isAnonymous={user.anonymous} radius="5px">
                {personsProfile.last_name}
              </AnonymousContainer>
            </NameTitle>
          </div>

          <div
            className="d-flex"
            style={{
              color: "#787C83",
              fontSize: "var(--font-size-md)",
              marginTop: "8px",
            }}
          >
            <AnonymousContainer isAnonymous={user.anonymous}>
              {getGlobalConfig("id_student_show", "yes") === "yes" &&
                personsProfile &&
                personsProfile.external_id &&
                personsProfile.external_id !== "" && (
                  <div>
                    ID: {personsProfile.external_id}{" "}
                    <OverlayTrigger
                      overlay={
                        <Tooltip>Copiar: {personsProfile.external_id}</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        onClick={() => copyInfo(personsProfile.external_id)}
                        className={`ml-1`}
                        size="1x"
                        icon={faClone}
                        style={{ cursor: "pointer" }}
                      />
                    </OverlayTrigger>
                  </div>
                )}
              {getGlobalConfig("id_student_show", "yes") === "yes" &&
                personsProfile &&
                personsProfile.external_id &&
                personsProfile.external_id !== "" &&
                validateDocument && (
                  <div
                    style={{
                      marginRight: "8px",
                      marginLeft: "8px",
                    }}
                  >
                    |
                  </div>
                )}
              {validateDocument && (
                <div>
                  {personsProfile.document_type === "Other"
                    ? translation("document_type_dynamic", "", t("other"))
                    : personsProfile.document_type}
                  : {personsProfile.document_number}
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        Copiar: {personsProfile.document_number}
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      onClick={() => copyInfo(personsProfile.document_number)}
                      className={`ml-1`}
                      size="1x"
                      icon={faClone}
                      style={{ cursor: "pointer" }}
                    />
                  </OverlayTrigger>
                </div>
              )}
            </AnonymousContainer>
          </div>

          <DividerH style={{ marginTop: "17px" }} />
          <div>
            <div
              className="d-flex align-items-center"
              style={{ padding: "12px 0px", fontSize: "var(--font-size-md)" }}
            >
              <FontAwesomeIcon
                icon={faUsersClass}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <strong style={{ marginRight: "5px" }}>
                {translation("tracking_object", "startCase", "Curso")}:{" "}
              </strong>{" "}
              {personsProfile.list_courses &&
                (selectTracking &&
                selectTracking.id &&
                personsProfile.tasks?.length > 1 ? (
                  <div>
                    <Form.Control
                      as="select"
                      value={selectTracking.id}
                      onChange={(event) => {
                        changeTrackingObject(event.target.value);
                      }}
                      style={{ width: "100%" }}
                    >
                      {personCourses
                        .filter((item) => item.id !== 0)
                        .map((item, key) => {
                          return (
                            <option key={`initiatives-${key}`} value={item.id}>
                              {item.name}{" "}
                              {personsProfile.tasks &&
                                getGlobalConfig(
                                  "show_tracking_object_external_id",
                                  false
                                ) &&
                                ` -  ${
                                  personsProfile.tasks.find((task) => {
                                    return (
                                      task?.tracking_object?.id === item.id
                                    );
                                  })?.tracking_object.external_id ?? ""
                                }`}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </div>
                ) : personsProfile.list_courses.length === 1 ? (
                  personsProfile.list_courses.join(", ")
                ) : (
                  "..."
                ))}
            </div>
            <DividerH />
          </div>
          <div>
            <div
              className="d-flex align-items-center"
              style={{ padding: "12px 0px", fontSize: "var(--font-size-md)" }}
            >
              <FontAwesomeIcon
                icon={faLandmark}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <strong style={{ marginRight: "5px" }}>
                {translation("campus", "startCase", "Sede")}:{" "}
              </strong>{" "}
              <AnonymousContainer isAnonymous={user.anonymous}>
                {selectTracking && selectTracking.campus_name
                  ? selectTracking.campus_name
                  : "..."}
              </AnonymousContainer>
            </div>
            <DividerH />
          </div>
          <div>
            <div
              className="d-flex align-items-center"
              style={{ padding: "12px 0px", fontSize: "var(--font-size-md)" }}
            >
              <FontAwesomeIcon
                icon={faPhone}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <strong style={{ marginRight: "5px" }}>
                {_.upperFirst(t("phone"))}:{" "}
              </strong>{" "}
              <AnonymousContainer isAnonymous={user.anonymous}>
                {personsProfile.phone ?? _.upperFirst(t("no_information"))}
              </AnonymousContainer>
              {personsProfile.phone && (
                <OverlayTrigger overlay={<Tooltip>{t("copy_phone")}</Tooltip>}>
                  <FontAwesomeIcon
                    onClick={() => copyInfo(personsProfile.phone)}
                    className={`ml-2 `}
                    size="1x"
                    icon={faClone}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
              )}
            </div>
            <DividerH />
          </div>
          <div>
            <div
              className="d-flex align-items-center"
              style={{ padding: "12px 0px", fontSize: "var(--font-size-md)" }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <strong style={{ marginRight: "5px" }}>
                {_.upperFirst(t("mail"))}:{" "}
              </strong>{" "}
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <AnonymousContainer isAnonymous={user.anonymous}>
                  {personsProfile.email ?? _.upperFirst(t("no_information"))}
                </AnonymousContainer>
              </div>
              {personsProfile.email && (
                <OverlayTrigger overlay={<Tooltip>{t("copy_email")}</Tooltip>}>
                  <FontAwesomeIcon
                    onClick={() => copyInfo(personsProfile.email)}
                    className={`ml-2`}
                    size="1x"
                    icon={faClone}
                    style={{ cursor: "pointer" }}
                  />
                </OverlayTrigger>
              )}
            </div>
            <DividerH />
          </div>
          <div>
            <div
              className="d-flex align-items-center"
              style={{ padding: "12px 0px", fontSize: "var(--font-size-md)" }}
            >
              <FontAwesomeIcon
                icon={faPhoneArrowUpRight}
                style={{ fontSize: "16px", marginRight: "8px" }}
              />
              <strong style={{ marginRight: "5px" }}>{t("last_call")}: </strong>{" "}
              {personsProfile &&
              personsProfile.last_contact != null &&
              personsProfile.last_contact.channel === "Call"
                ? moment(personsProfile.last_contact.closed_at).format(
                    "YYYY-MM-DD"
                  )
                : "..."}
            </div>
            <DividerH />
          </div>
        </CardNimbi>
      )}
      {!isPendingProfile && personsProfile && (
        <CardNimbi
          radius="8px"
          padding="29px 26px"
          style={{ marginTop: "16px" }}
        >
          <div className="d-flex">
            <IconContainer size="42px">
              <NIcon
                iconname="projectDiagram"
                style={{ fontSize: "var(--font-size-md)" }}
              />
            </IconContainer>
            <div
              style={{
                marginLeft: "14.25px",
                fontSize: "var(--font-size-md)",
              }}
            >
              <strong
                style={{
                  fontSize: "var(--font-size-lg)",
                  marginBottom: "2px",
                }}
              >
                {_.upperFirst(_.lowerCase(t("action_plans_active")))}
              </strong>
              {selectTracking &&
                selectTracking?.student_tracking_id &&
                momentsActive &&
                !momentsActive[selectTracking?.student_tracking_id] && (
                  <div
                    style={{
                      fontSize: "var(--font-size-md)",
                      paddingTop: "2px",
                    }}
                  >
                    <AnonymousContainer
                      isAnonymous={
                        user.anonymous &&
                        personsProfile.action_plans_active.length !== 0
                      }
                    >
                      {selectTracking &&
                      selectTracking.action_plans &&
                      selectTracking.action_plans.length > 0
                        ? selectTracking.action_plans.join(", ")
                        : "..."}
                    </AnonymousContainer>
                  </div>
                )}
              {selectTracking &&
                selectTracking?.student_tracking_id &&
                momentsActive &&
                momentsActive[selectTracking?.student_tracking_id] && (
                  <div
                    style={{
                      fontSize: "var(--font-size-md)",
                      paddingTop: "2px",
                    }}
                  >
                    <AnonymousContainer
                      isAnonymous={
                        user.anonymous &&
                        momentsActive[selectTracking?.student_tracking_id]
                          .length !== 0
                      }
                    >
                      {momentsActive[selectTracking?.student_tracking_id]
                        .map((item) => item.name)
                        .join(", ")}
                    </AnonymousContainer>
                  </div>
                )}
            </div>
          </div>
          {StudentTrackingIsActive &&
            enterable_action_plans &&
            enterable_action_plans.length > 0 && (
              <div className="text-center mt-2">
                <span
                  onClick={() => {
                    setLoading(false);
                    setShow(true);
                  }}
                  style={{
                    color: "#334FFF",
                    textDecorationLine: "underline",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  {t("enter_action_plan_1")}
                </span>
              </div>
            )}
        </CardNimbi>
      )}
      {!isPendingProfile && personsProfile && (
        <CardNimbi
          radius="8px"
          padding="29px 26px"
          style={{ marginTop: "16px" }}
        >
          <div className="d-flex">
            <IconContainer size="42px">
              <NIcon
                iconname="externalLink"
                style={{ fontSize: "var(--font-size-md)" }}
              />
            </IconContainer>
            <div
              style={{
                marginLeft: "14.25px",
                fontSize: "var(--font-size-md)",
              }}
            >
              <strong
                style={{
                  fontSize: "var(--font-size-lg)",
                  marginBottom: "2px",
                }}
              >
                {t("external_links")}
              </strong>
              <div
                style={{
                  fontSize: "var(--font-size-md)",
                  paddingTop: "2px",
                }}
              >
                <div
                  style={{
                    flexWrap: "wrap",
                    display: "flex",
                    columnGap: "10px",
                    rowGap: "2px",
                  }}
                >
                  {personsProfile && personsProfile.link_panel && (
                    <div>
                      <a
                        href={personsProfile.link_panel}
                        style={{
                          color: "#334FFF",
                          textDecorationLine: "underline",
                          fontWeight: "500",
                        }}
                      >
                        {t("view_dashboard")}
                      </a>
                    </div>
                  )}
                  {personsProfile && personsProfile.link_inquisidor && (
                    <div>
                      <a
                        href={personsProfile.link_inquisidor}
                        style={{
                          color: "#334FFF",
                          textDecorationLine: "underline",
                          fontWeight: "500",
                        }}
                      >
                        {t("see_the_nquisitor")}
                      </a>
                    </div>
                  )}
                  {personsProfile && personsProfile.link_zendesk && (
                    <div>
                      <a
                        href={personsProfile.link_zendesk}
                        style={{
                          color: "#334FFF",
                          textDecorationLine: "underline",
                          fontWeight: "500",
                        }}
                      >
                        {t("view_zendesk")}
                      </a>
                    </div>
                  )}
                  {extras &&
                    "url_external_list" in extras &&
                    extras["url_external_list"].map((item, key) => {
                      return (
                        <div key={`key-url-extras${key}`}>
                          <a
                            href={item.url}
                            style={{
                              color: "#334FFF",
                              textDecorationLine: "underline",
                              fontWeight: "500",
                            }}
                          >
                            {item.title}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </CardNimbi>
      )}
      <ModalDesign
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
      >
        <Modal.Header closeButton style={{ borderBottom: "0px" }}>
          <Modal.Title className="h4">{t("enter_action_plan")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginBottom: "40px" }}>
            {t("select_action_plan_enter")}
          </div>
          <Formik
            initialValues={{}}
            validate={validate}
            onSubmit={onFormSubmit}
          >
            {({
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              values,
            }) => (
              <div>
                <Form.Control
                  as="select"
                  name="action_plan"
                  onChange={(event) => {
                    handleChange(event);
                    let value = event.target.value;
                    getListReason(value);
                    setFieldValue("reason_admission", null, false);
                  }}
                >
                  <option value="">---{_.upperFirst(t("select"))}---</option>
                  {enterable_action_plans &&
                    enterable_action_plans.map((item, key) => (
                      <option key={`moment-derive-${key}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <TextError
                  text={errors["action_plan"]}
                  marginTop="5px"
                  icon={faExclamationCircle}
                />
                {reason && reason?.length > 0 && (
                  <>
                    <strong>{t("select_reason_admission")}</strong>
                    <Form.Control
                      as="select"
                      name="enter_reason_id"
                      onChange={handleChange}
                      disabled={loadingReason || !reason}
                    >
                      <option value="">
                        --- {_.upperFirst(t("select_reason_admission"))} ---
                      </option>
                      {reason &&
                        reason.map((item, key) => (
                          <option key={`key-reason-${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Control>
                    <TextError
                      text={errors["enter_reason_id"]}
                      marginTop="5px"
                      icon={faExclamationCircle}
                    />
                  </>
                )}
                <div style={{ marginTop: "40px", marginBottom: "20px" }}>
                  <Form.Check
                    id={`confirm-enter-action-plan`}
                    name={`confirm-enter-action-plan`}
                    type="checkbox"
                    data-key="confirmEnterActionPlan"
                    label={t("confirm_enter_action_plan")}
                    checked={check}
                    onChange={(event) => {
                      setCheck(event.target.checked);
                    }}
                    disabled={false}
                    custom
                  />
                </div>
                <RowItem>
                  <Col xs="6">
                    <NButton
                      style={{
                        marginTop: "10px",
                        float: "right",
                        width: "100%",
                      }}
                      outlined
                      onClick={() => {
                        setShow(false);
                      }}
                      disabled={loading}
                    >
                      {_.upperFirst(t("cancel"))}
                    </NButton>
                  </Col>
                  <Col xs="6">
                    <NButton
                      style={{
                        marginTop: "10px",
                        float: "right",
                        width: "100%",
                      }}
                      onClick={handleSubmit}
                      loading={loading}
                      disabled={loading || !(values.action_plan && check)}
                    >
                      {_.upperFirst(t("enter"))}
                    </NButton>
                  </Col>
                </RowItem>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </ModalDesign>
    </div>
  );
};
export default StudentProfile;
export const Title = styled.div`
  font-family: "Nova";
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-small);
  line-height: 120%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #989da5;
`;

export const NameTitle = styled.div`
  font-family: "Nova";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #334fff;
`;
const ModalDesign = styled(Modal)`
  .modal-header {
    padding-top: 30px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 0px;
  }
  .modal-body {
    padding-top: 8px;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .modal-content {
    border-radius: 8px;
  }
`;
