import React, { useCallback, useState } from "react";
import { NSidebarContainer } from "components";
import { createExternalManagement } from "services/externalManagement.service";
import { fetchExternalManagement } from "store/actions/async/external-management-async.action";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormExternalManagement } from "containers/ExternalManagement";
import { fetchPersonHistory } from "store/actions/async/crm-async.action";

const FormSlideExternalManagement = ({
  open,
  student_tracking_id,
  onClose,
  studentID,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clean, setClean] = useState(false);

  const initialValues = {
    closed_at: null,
    channel: null,
    student: null,
    management_state: null,
    management_type: null,
    management_topic: null,
    comment: null,
    with_notification: false,
  };
  const saveChanges = useCallback(
    async (values, { setSubmitting }) => {
      values["student_tracking"] = student_tracking_id;
      if (!values?.with_notification) {
        delete values["with_notification"];
        delete values["notification_email"];
      }
      const { status, title, message } = await createExternalManagement(values);
      setSubmitting(false);
      if (status === 201) {
        toast.success(t("success_create_external"));
        onClose();
        // setObjectCreate(data);
        dispatch(
          fetchExternalManagement({ student_tracking: student_tracking_id })
        );
        setClean(true);
        setTimeout(() => {
          setClean(false);
        }, 1000);
        if (studentID) {
          dispatch(fetchPersonHistory(studentID));
        }
      } else if (status === 400) {
        toast.error(`${title} ${message}`);
        return false;
      } else {
        toast.error(t("error_create"));
      }
    },
    [student_tracking_id, dispatch, t, onClose, studentID]
  );
  return (
    <NSidebarContainer
      width="634px"
      openSide={open}
      title={"Nuevo registro"}
      onClose={onClose}
    >
      {!clean && (
        <FormExternalManagement
          formValues={initialValues}
          onFormSubmit={saveChanges}
          slide
          onClose={onClose}
        />
      )}
    </NSidebarContainer>
  );
};
export default FormSlideExternalManagement;
